import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Notifications } from "@mantine/notifications";
import { NavigationProgress } from "@mantine/nprogress";
import { MantineProvider } from "@mantine/core";
import Shell from "./sharedComponents/shell";
import Login from "./pages/account/login";
import Registration from "./pages/account/registration";
import RegistrationSuccess from "./pages/account/registrationSuccess";
import EmailVerification from "./pages/account/emailVerification";
import Dashboard from "./pages/dashboard/dashboard";
import UserSettings from "./pages/account/userSettings";
import Lending from "./pages/lending/lending";
import Members from "./pages/members/members";
import MemberCardPrinting from "./pages/members/memberCardPrinting";
import History from "./pages/lending/history";
import Catalogue from "./pages/catalogue/catalogue";
import UnhandledError from "./pages/unhandledError";
import NotFound from "./pages/errors/notFound";
import Logout from "./pages/account/logout";
import ProtectedRoute from "./sharedComponents/protectedRoute";
import NavProgressWatcher from "./sharedComponents/navProgressWatcher";
import TagManagerModal from "./pages/members/tagManagerModal";
var rootElement = document.getElementById("app");
var root = createRoot(rootElement);
var router = createBrowserRouter([
    {
        element: React.createElement(NavProgressWatcher, null),
        children: [
            {
                path: "/",
                element: React.createElement(Shell.Page, null),
                loader: Shell.Loader,
                errorElement: React.createElement(UnhandledError.Page, null),
                children: [
                    {
                        path: "/",
                        element: (React.createElement(ProtectedRoute, null,
                            React.createElement(Dashboard.Page, null))),
                        loader: Dashboard.Loader,
                    },
                    {
                        path: "logout",
                        action: Logout.Action,
                        element: React.createElement(Logout.Page, null),
                    },
                    {
                        path: "login",
                        element: React.createElement(Login.Page, null),
                        action: Login.Action,
                    },
                    {
                        path: "register",
                        element: React.createElement(Registration.Page, null),
                        action: Registration.Action,
                    },
                    { path: "register/success", element: React.createElement(RegistrationSuccess.Page, null) },
                    {
                        path: "verifyEmail",
                        element: React.createElement(EmailVerification.Page, null),
                        loader: EmailVerification.Loader,
                        action: EmailVerification.Action,
                        shouldRevalidate: function () { return false; }, // Don't reload with a broken token after clicking resend
                    },
                    {
                        path: "catalogue",
                        element: (React.createElement(ProtectedRoute, null,
                            React.createElement(Catalogue.Page, null))),
                    },
                    {
                        path: "members",
                        element: (React.createElement(ProtectedRoute, null,
                            React.createElement(Members.Page, null))),
                        loader: Members.Loader,
                        action: Members.Action,
                        children: [
                            {
                                path: "tags",
                                element: React.createElement(TagManagerModal.Modal, null),
                                action: TagManagerModal.Action,
                            },
                        ],
                    },
                    {
                        path: "members/print",
                        loader: MemberCardPrinting.Loader,
                        element: (React.createElement(ProtectedRoute, null,
                            React.createElement(MemberCardPrinting.Page, null))),
                    },
                    {
                        path: "lending",
                        element: (React.createElement(ProtectedRoute, null,
                            React.createElement(Lending.Page, null))),
                    },
                    {
                        path: "history",
                        element: (React.createElement(ProtectedRoute, null,
                            React.createElement(History.Page, null))),
                        loader: History.Loader,
                    },
                    {
                        path: "userSettings",
                        element: (React.createElement(ProtectedRoute, null,
                            React.createElement(UserSettings.Page, null))),
                    },
                    {
                        path: "*",
                        element: React.createElement(NotFound.Page, null),
                    },
                ],
            },
        ],
    },
]);
root.render(React.createElement(MantineProvider, { withGlobalStyles: true, withNormalizeCSS: true },
    React.createElement(Notifications, { position: "top-right" }),
    React.createElement(NavigationProgress, { transitionDuration: 750, color: "teal" }),
    React.createElement(React.StrictMode, null,
        React.createElement(RouterProvider, { router: router }))));
