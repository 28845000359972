var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { Group, Code, Navbar as MantineNavbar, createStyles, getStylesRef, rem, } from "@mantine/core";
import { IconUsers, IconHistory, IconSwitchHorizontal, IconLogout, IconHomeStats, IconUserCircle, } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import Logo from "./logo";
var useStyles = createStyles(function (theme) {
    var _a;
    return ({
        navbar: {
            backgroundImage: "linear-gradient(-60deg, ".concat(theme.colors.blue[4], " 0%, ").concat(theme.colors.blue[7], " 100%)"),
        },
        version: {
            backgroundColor: theme.fn.lighten(theme.fn.variant({ variant: "filled", color: theme.primaryColor })
                .background, 0.1),
            color: theme.white,
            fontWeight: 700,
        },
        header: {
            paddingBottom: theme.spacing.md,
            marginBottom: "calc(".concat(theme.spacing.md, " * 1.5)"),
            borderBottom: "".concat(rem(1), " solid ").concat(theme.fn.lighten(theme.fn.variant({ variant: "filled", color: theme.primaryColor })
                .background, 0.1)),
        },
        footer: {
            paddingTop: theme.spacing.md,
            marginTop: theme.spacing.md,
            borderTop: "".concat(rem(1), " solid ").concat(theme.fn.lighten(theme.fn.variant({ variant: "filled", color: theme.primaryColor })
                .background, 0.1)),
        },
        link: __assign(__assign({}, theme.fn.focusStyles()), { display: "flex", alignItems: "center", textDecoration: "none", fontSize: theme.fontSizes.sm, color: theme.white, padding: "".concat(theme.spacing.xs, " ").concat(theme.spacing.sm), borderRadius: theme.radius.sm, fontWeight: 500, "&:hover": {
                backgroundColor: theme.fn.lighten(theme.fn.variant({ variant: "filled", color: theme.primaryColor })
                    .background, 0.1),
            } }),
        linkIcon: {
            ref: getStylesRef("icon"),
            color: theme.white,
            opacity: 0.75,
            marginRight: theme.spacing.sm,
        },
        linkActive: {
            "&, &:hover": (_a = {
                    backgroundColor: theme.fn.lighten(theme.fn.variant({ variant: "filled", color: theme.primaryColor })
                        .background, 0.15)
                },
                _a["& .".concat(getStylesRef("icon"))] = {
                    opacity: 0.9,
                },
                _a),
        },
    });
});
export function Navbar(_a) {
    var userName = _a.userName;
    var _b = useStyles(), classes = _b.classes, cx = _b.cx;
    var items = [
        {
            label: "Dashboard",
            icon: IconHomeStats,
            to: "/",
        },
        {
            label: "Members",
            icon: IconUsers,
            to: "/members",
        },
        /*{
          label: "Catalogue",
          icon: IconBooks,
          to: "/catalogue",
        },*/
        {
            label: "Lending",
            icon: IconSwitchHorizontal,
            to: "/lending",
        },
        {
            label: "Lending History",
            icon: IconHistory,
            to: "/history",
        },
    ];
    var makeNavLink = function (to, label, Icon) {
        var activeClasses = cx(classes.link, classes.linkActive);
        var inactiveClasses = classes.link;
        return (React.createElement(NavLink, { to: to, key: label, className: function (_a) {
                var isActive = _a.isActive, isPending = _a.isPending;
                return isActive || isPending ? activeClasses : inactiveClasses;
            } },
            React.createElement(Icon, { className: classes.linkIcon, stroke: 1.5 }),
            React.createElement("span", null, label)));
    };
    var links = items.map(function (item) {
        return makeNavLink(item.to, item.label, item.icon);
    });
    var bottomLinks = [
        makeNavLink("/userSettings", userName, IconUserCircle),
        makeNavLink("/logout", "Logout", IconLogout),
    ];
    return (React.createElement(MantineNavbar, { fixed: false, width: { sm: 300 }, p: "md", className: classes.navbar },
        React.createElement(MantineNavbar.Section, { grow: true },
            React.createElement(Group, { className: classes.header, position: "apart" },
                React.createElement(Logo, { inverted: true }),
                React.createElement(Code, { className: classes.version }, "v2.0.0")),
            links),
        React.createElement(MantineNavbar.Section, { className: classes.footer }, bottomLinks)));
}
