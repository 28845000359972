import * as React from "react";
import { Navigate } from "react-router-dom";
import Api from "../api/api";
var ProtectedRoute = function (_a) {
    var children = _a.children;
    if (!Api.IsAuthenticated()) {
        return React.createElement(Navigate, { to: "/logout", replace: true });
    }
    return children;
};
export default ProtectedRoute;
