import { ActionIcon, AspectRatio, Badge, Card, Group, Stack, Text, } from "@mantine/core";
import { IconAdjustments } from "@tabler/icons-react";
import * as React from "react";
import noCover from "../assets/no-cover.png";
function BookCard(props) {
    var showStock = !!props.totalStock;
    var imageSrc = props.imageUrl || noCover;
    return (React.createElement(AspectRatio, { ratio: 10 / 6, radius: "md" },
        React.createElement(Card, { radius: "md", shadow: "md", p: 0 },
            React.createElement(Group, { pl: 0, noWrap: true, p: 0, sx: { height: "100%", width: "100%" } },
                React.createElement("img", { src: imageSrc, alt: props.title, style: {
                        height: "100%",
                        width: "auto",
                        marginRight: "0",
                        paddingRight: "0",
                    } }),
                React.createElement(Stack, { justify: "space-between", pr: "xs", pb: "xs", pt: "4px", pl: "0", ml: "-2%", m: "0", sx: { height: "100%", width: "100%" } },
                    React.createElement("div", null,
                        React.createElement(Text, { fz: "xl", fw: 500, lh: "sm" }, props.title),
                        React.createElement(Text, { fz: "md", c: "dimmed" },
                            props.author,
                            ", ",
                            props.year)),
                    React.createElement(Group, { position: "apart" },
                        showStock && (React.createElement(Badge, { variant: "light", size: "md" },
                            props.stockLevel,
                            "/",
                            props.totalStock,
                            " in stock")),
                        props.showActionButton && (React.createElement(ActionIcon, { color: "blue" },
                            React.createElement(IconAdjustments, null)))))))));
}
export default BookCard;
