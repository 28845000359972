import * as React from "react";
import { HoverCard, Space, Text } from "@mantine/core";
import { IconHelp } from "@tabler/icons-react";
export default function HistoryPageHelp() {
    return (React.createElement(HoverCard, { width: 400, shadow: "md" },
        React.createElement(HoverCard.Target, null,
            React.createElement(IconHelp, null)),
        React.createElement(HoverCard.Dropdown, null,
            React.createElement(Text, { size: "sm" }, "This page displays a history of all books that have been checked out. Toggle between showing active and completed loans using the tabs at the top of the page."),
            React.createElement(Space, { h: "md" }),
            React.createElement(Text, { size: "sm" }, "The list can be filtered by book (Title / Author), Member Name, and Member Tags."),
            React.createElement(Space, { h: "md" }),
            React.createElement(Text, { size: "sm" }, "You can choose to show Active (unreturned) loans, Completed (returned) loans, or all loans using the selector at the top of the list."))));
}
