import { Center, Title } from "@mantine/core";
import { IconBulb } from "@tabler/icons-react";
import * as React from "react";
// Logo function using props
export default function Logo(_a) {
    var inverted = _a.inverted;
    return (React.createElement(Center, { inline: true },
        React.createElement(IconBulb, { color: "white" }),
        React.createElement(Title, { color: inverted ? "white" : undefined, size: "h3", ml: "sm", align: "left" }, "Clever Library")));
}
