import { Anchor, Center, Container, Paper, Space, Text } from "@mantine/core";
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import StockBackground from "../../sharedComponents/stockBackground";
export function RegistrationSuccessPage() {
    var _a;
    var searchParams = useSearchParams()[0];
    var email = (_a = searchParams.get("email")) !== null && _a !== void 0 ? _a : "the address you provided when registering";
    return (React.createElement(React.Fragment, null,
        React.createElement(StockBackground, null),
        React.createElement(Container, { size: 420 },
            React.createElement(Paper, { withBorder: true, shadow: "md", p: 30, mt: 60, radius: "md" },
                React.createElement(Center, { inline: true },
                    React.createElement("img", { src: logo, alt: "Clever Library", width: "70%" })),
                React.createElement(Text, null,
                    "You're almost there! We've sent an email to ",
                    email,
                    ". Just click on the link in that email to complete your registration."),
                React.createElement(Space, { h: "md" }),
                React.createElement(Text, null, "If you don't see it, you may need to check your spam folder."),
                React.createElement(Space, { h: "md" }),
                React.createElement(Text, { color: "dimmed", size: "sm", align: "center", m: "sm" },
                    React.createElement(Anchor, { component: Link, to: "/login" }, "Return to Login Screen."))))));
}
export default {
    Page: RegistrationSuccessPage,
};
