import * as React from "react";
import { HoverCard, Space, Text } from "@mantine/core";
import { IconHelp } from "@tabler/icons-react";
export default function MembersPageHelp() {
    return (React.createElement(HoverCard, { width: 400, shadow: "md" },
        React.createElement(HoverCard.Target, null,
            React.createElement(IconHelp, null)),
        React.createElement(HoverCard.Dropdown, null,
            React.createElement(Text, { size: "sm" }, "You can add members individually by clicking the \"Add Member\" button."),
            React.createElement(Space, { h: "md" }),
            React.createElement(Text, { size: "sm" },
                "Alternatively, upload a CSV file to import members in bulk. The file should have a header row and one member per row. The file should contain the following columns:",
                React.createElement("dl", null,
                    React.createElement("dt", null, "FirstName"),
                    React.createElement("dd", null, "The member's first name."),
                    React.createElement("dt", null, "LastName"),
                    React.createElement("dd", null, "The member's last name."),
                    React.createElement("dt", null, "Tags"),
                    React.createElement("dd", null, "A comma separated list of tag names that should be applied to the member. Tags must already exist, they will not be added automatically.")),
                "Ensure that no other columns are included in the file."))));
}
