var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Badge } from "@mantine/core";
import * as React from "react";
import { forwardRef } from "react";
var TagMultiSelectItem = forwardRef(function (_a, ref) {
    var tag = _a.tag, label = _a.label, value = _a.value, others = __rest(_a, ["tag", "label", "value"]);
    return (React.createElement("div", __assign({ ref: ref }, others),
        React.createElement(Badge, { variant: "outline", styles: function () { return ({
                root: {
                    borderColor: tag.colour.value,
                    color: tag.colour.value,
                },
            }); }, mr: "sm", size: "md" }, tag.label)));
});
export default TagMultiSelectItem;
