var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { notifications } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
export function ShowApiError(response) {
    var _a, _b, _c, _d, _e;
    return __awaiter(this, void 0, void 0, function () {
        var body, jsonBody, status;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0: return [4 /*yield*/, response.text()];
                case 1:
                    body = _f.sent();
                    jsonBody = {};
                    try {
                        jsonBody = JSON.parse(body);
                    }
                    catch (err) {
                        jsonBody = {};
                    }
                    console.error("API Error", body);
                    status = (_a = jsonBody.status) !== null && _a !== void 0 ? _a : response.status;
                    if (status === 404) {
                        notifications.show({
                            title: (_b = jsonBody.title) !== null && _b !== void 0 ? _b : "Not Found",
                            message: (_c = jsonBody.detail) !== null && _c !== void 0 ? _c : "The page you are looking for does not exist",
                            withBorder: true,
                            color: "orange",
                            icon: React.createElement(IconX, null),
                        });
                    }
                    else if (status === 400) {
                        notifications.show({
                            title: (_d = jsonBody.title) !== null && _d !== void 0 ? _d : "Request Error",
                            message: (_e = jsonBody.detail) !== null && _e !== void 0 ? _e : "An error occurred submitting your data.",
                            withBorder: true,
                            color: "orange",
                            icon: React.createElement(IconX, null),
                        });
                    }
                    else if (response.status === 401 || response.status === 403) {
                        notifications.show({
                            title: "Unauthorised",
                            withBorder: true,
                            message: "You do not have permission to perform this action, please log out and log back in if you think your permissions may have changed.",
                            color: "red",
                            icon: React.createElement(IconX, null),
                        });
                    }
                    else {
                        notifications.show({
                            title: "Unexpected Error",
                            message: "An error occurred while handling your request.",
                            withBorder: true,
                            icon: React.createElement(IconX, null),
                            color: "red",
                        });
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export function ShowSuccess(message) {
    notifications.show({
        title: "Success",
        message: message,
        withBorder: true,
        color: "green",
        icon: React.createElement(IconCheck, null),
    });
}
export default {
    ShowApiError: ShowApiError,
    ShowSuccess: ShowSuccess,
};
