var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Badge, Button, ColorInput, Group, Modal, Space, Table, TextInput, } from "@mantine/core";
import * as React from "react";
import { useNavigate, useOutletContext, } from "react-router-dom";
import { useForm } from "@mantine/form";
import Api from "../../api/api";
import { ActionButton } from "../../sharedComponents/actionLink";
import NotificationHelpers from "../../utils/notificationHelpers";
var action = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestData, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, request.json()];
                case 1:
                    requestData = _b.sent();
                    console.log(request);
                    if (!(request.method === "DELETE")) return [3 /*break*/, 3];
                    console.log("deleting tag", requestData);
                    return [4 /*yield*/, Api.DeleteTag(requestData.id, request.signal)];
                case 2:
                    response = _b.sent();
                    return [3 /*break*/, 8];
                case 3:
                    if (!requestData.id) return [3 /*break*/, 5];
                    return [4 /*yield*/, Api.UpdateTag(requestData.id, requestData.label, requestData.colour, request.signal)];
                case 4:
                    response = _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, Api.CreateTag(requestData.label, requestData.colour, request.signal)];
                case 6:
                    response = _b.sent();
                    _b.label = 7;
                case 7:
                    if (!response.ok) {
                        NotificationHelpers.ShowApiError(response);
                        // We've displayed an error, so just swallow and remain on the current form
                        return [2 /*return*/, { ok: true }];
                    }
                    _b.label = 8;
                case 8: return [2 /*return*/, { ok: true }];
            }
        });
    });
});
export function TagManagerModal() {
    var navigate = useNavigate();
    var _a = React.useState(false), adding = _a[0], setAdding = _a[1];
    var tags = useOutletContext().tags;
    var tagIds = tags.map(function (t) { return t.id; }).join(",");
    React.useEffect(function () {
        setAdding(false);
    }, [tagIds]);
    return (React.createElement(Modal, { opened: true, onClose: function () { return navigate(".."); }, title: "Member Tags", size: "xl" },
        React.createElement(Space, { h: "xl" }),
        React.createElement(Group, { position: "left" },
            React.createElement(Button, { onClick: function () { return setAdding(true); } }, "Add Tag")),
        React.createElement(Space, { h: "md" }),
        React.createElement(Table, { verticalSpacing: "md" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Label"),
                    React.createElement("th", null, "Colour"),
                    React.createElement("th", null))),
            React.createElement("tbody", null,
                adding && (React.createElement(TagEditRow, { tag: { id: null, label: "", colour: { value: "" } }, onCancel: function () { return setAdding(false); } })),
                tags
                    .sort(function (a, b) { return a.label.localeCompare(b.label); })
                    .map(function (tag) { return (React.createElement(TagRow, { tag: tag })); })))));
}
function TagRow(_a) {
    var tag = _a.tag;
    var _b = React.useState(false), editing = _b[0], setEditing = _b[1];
    React.useEffect(function () {
        setEditing(false);
    }, [tag.label, tag.colour.value]);
    return editing ? (React.createElement(TagEditRow, { tag: tag, onCancel: function () { return setEditing(false); } })) : (React.createElement(TagViewRow, { tag: tag, onEdit: function () { return setEditing(true); } }));
}
function TagViewRow(_a) {
    var _b;
    var tag = _a.tag, onEdit = _a.onEdit;
    return (React.createElement("tr", { key: tag.id },
        React.createElement("td", null, tag.label),
        React.createElement("td", null,
            React.createElement(Badge, { variant: "filled", styles: function () { return ({
                    root: {
                        backgroundColor: tag.colour.value,
                        color: "white",
                    },
                }); }, mr: "sm", size: "lg" }, tag.colour.value)),
        React.createElement("td", null,
            React.createElement(Group, { spacing: "md", position: "right" },
                React.createElement(Button, { variant: "outline", onClick: onEdit }, "Edit"),
                React.createElement(ActionButton, { type: "button", text: "Delete", route: "", body: { id: (_b = tag.id) === null || _b === void 0 ? void 0 : _b.value }, method: "delete", enabled: true, color: "red", variant: "outline" })))));
}
function TagEditRow(_a) {
    var _b;
    var tag = _a.tag, onCancel = _a.onCancel;
    var form = useForm({
        initialValues: {
            label: tag.label,
            colour: tag.colour.value,
        },
        validate: {
            label: function (value) { return (value.length == 0 ? "Enter a label" : null); },
            colour: function (value) { return (value.length == 0 ? "Enter a colour" : null); },
        },
        validateInputOnBlur: true,
    });
    var key = tag.id ? tag.id.value : "new";
    return (React.createElement("tr", { key: key },
        React.createElement("td", null,
            React.createElement(TextInput, __assign({ name: "label", required: true, placeholder: "Label..." }, form.getInputProps("label")))),
        React.createElement("td", null,
            React.createElement(ColorInput, __assign({ name: "colour", required: true }, form.getInputProps("colour")))),
        React.createElement("td", null,
            React.createElement(Group, { spacing: "md", position: "right" },
                React.createElement(ActionButton, { type: "button", text: "Save", route: "", method: "post", enabled: form.isDirty() && form.isValid(), body: __assign({ id: (_b = tag.id) === null || _b === void 0 ? void 0 : _b.value }, form.values) }),
                React.createElement(Button, { onClick: onCancel }, "Cancel")))));
}
export default {
    Modal: TagManagerModal,
    Action: action,
};
