var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AppShell, Box } from "@mantine/core";
import * as React from "react";
import { Outlet, useLocation, useNavigation, useSearchParams, } from "react-router-dom";
import { Navbar } from "./navBar";
import Api from "../api/api";
import { redirect, useLoaderData, } from "react-router-typesafe";
var loader = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        var userResponse, userData;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!Api.IsAuthenticated()) {
                        return [2 /*return*/, { authenticated: false, user: null }];
                    }
                    return [4 /*yield*/, Api.GetCurrentUser(request.signal)];
                case 1:
                    userResponse = _b.sent();
                    if (!!userResponse.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, Api.Logout()];
                case 2:
                    _b.sent();
                    return [2 /*return*/, redirect("/login")];
                case 3: return [4 /*yield*/, userResponse.json()];
                case 4:
                    userData = _b.sent();
                    return [2 /*return*/, { authenticated: true, user: userData }];
            }
        });
    });
});
var logout = (function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.Logout()];
            case 1:
                _a.sent();
                return [2 /*return*/, redirect("/login")];
        }
    });
}); });
export function Shell() {
    var _a, _b, _c;
    var _d = useLoaderData(), authenticated = _d.authenticated, user = _d.user;
    var navigation = useNavigation();
    var _e = useSearchParams(), searchParams = _e[0], _ = _e[1];
    var userName = ((_a = user === null || user === void 0 ? void 0 : user.name) === null || _a === void 0 ? void 0 : _a.firstName) + " " + ((_b = user === null || user === void 0 ? void 0 : user.name) === null || _b === void 0 ? void 0 : _b.lastName);
    var currentLocation = useLocation();
    var hideNavigation = searchParams.get("kiosk") === "true";
    var isChangingPage = navigation.state === "loading" &&
        ((_c = navigation === null || navigation === void 0 ? void 0 : navigation.location) === null || _c === void 0 ? void 0 : _c.pathname) !== (currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.pathname);
    return (React.createElement(AppShell, { padding: "lg", hidden: authenticated === false || hideNavigation, navbar: React.createElement(Navbar, { userName: userName }), styles: function (theme) { return ({
            main: {
                backgroundColor: theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : theme.colors.gray[1],
            },
        }); } },
        React.createElement(Box, { pos: "relative", sx: {
                backgroundColour: "transparent",
                width: "100%",
                height: "100%",
            } }, !isChangingPage && React.createElement(Outlet, null))));
}
export default {
    Loader: loader,
    LogoutAction: logout,
    Page: Shell,
};
