var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { Title, SimpleGrid, } from "@mantine/core";
import BookCard from "../../sharedComponents/bookCard";
var loader = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, { success: true }];
        });
    });
});
var action = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, { success: true }];
        });
    });
});
function CataloguePage() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { order: 3 }, "Catalogue"),
        React.createElement(SimpleGrid, { cols: 4, breakpoints: [
                { maxWidth: "95rem", cols: 3, spacing: "md" },
                { maxWidth: "80rem", cols: 2, spacing: "md" },
                { maxWidth: "60rem", cols: 1, spacing: "md" },
            ] },
            React.createElement(BookCard, { id: "123", stockLevel: 3, totalStock: 5, title: "Harry Potter and the and the Chamber of Secrets", author: "J.K. Rowling", year: "1998", imageUrl: "https://covers.openlibrary.org/b/id/12376625-M.jpg", showActionButton: true }),
            React.createElement(BookCard, { id: "123", stockLevel: 3, totalStock: 5, title: "A Game of Thrones", author: "George R. R. Martin", year: "1996", imageUrl: "https://covers.openlibrary.org/b/id/11291394-L.jpg", showActionButton: true }),
            React.createElement(BookCard, { id: "123", stockLevel: 3, totalStock: 5, title: "Red, White & Royal Blue", author: "Casey McQuiston & Ramon De Ocampo", year: "2019", imageUrl: "https://ia800507.us.archive.org/view_archive.php?archive=/8/items/l_covers_0009/l_covers_0009_17.zip&file=0009171544-L.jpg", showActionButton: true }),
            React.createElement(BookCard, { id: "123", stockLevel: 3, totalStock: 5, title: "Harry Potter and the and the Chamber of Secrets", author: "J.K. Rowling", year: "1998", imageUrl: "https://covers.openlibrary.org/b/id/12376625-M.jpg", showActionButton: true }),
            React.createElement(BookCard, { id: "123", stockLevel: 3, totalStock: 5, title: "Harry Potter and the and the Chamber of Secrets", author: "J.K. Rowling", year: "1998", imageUrl: "https://covers.openlibrary.org/b/id/12376625-M.jpg", showActionButton: true }))));
}
export default {
    Loader: loader,
    Action: action,
    Page: CataloguePage,
};
