var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { Box, Center, Container, Group, SimpleGrid, Paper, SegmentedControl, Space, Stepper, Title, Text, Button, Avatar, } from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import { IconBookUpload, IconBookDownload, IconBook, IconIdBadge2, IconCheck, IconUser, IconArrowsRight, IconCircleCheck, } from "@tabler/icons-react";
import Api from "../../api/api";
import useScanDetection from "use-scan-detection-react18";
import BookCard from "../../sharedComponents/bookCard";
import Minidenticon from "../../sharedComponents/identicon";
var loader = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, { success: true }];
        });
    });
});
var action = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, { success: true }];
        });
    });
});
function LendingPage() {
    var _a = React.useState("checkout"), action = _a[0], setAction = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, "Lending"),
        React.createElement(Container, { size: "100%" },
            React.createElement(Center, null,
                React.createElement(SegmentedControl, { size: "lg", color: "blue", radius: "md", value: action, onChange: setAction, data: [
                        {
                            label: (React.createElement(Center, null,
                                React.createElement(IconBookUpload, { size: "1.6rem" }),
                                React.createElement(Box, { ml: 10 }, "Check a Book Out"))),
                            value: "checkout",
                        },
                        {
                            label: (React.createElement(Center, null,
                                React.createElement(IconBookDownload, { size: "1.6rem" }),
                                React.createElement(Box, { ml: 10 }, "Return a Book"))),
                            value: "return",
                        },
                    ] })),
            React.createElement(Space, { h: "xl" }),
            React.createElement(LendingPageProcess, { action: action, key: action }))));
}
function LendingPageProcess(props) {
    var _this = this;
    var _a = React.useState(0), activeStep = _a[0], setActiveStep = _a[1];
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var _c = React.useState(null), book = _c[0], setBook = _c[1];
    var _d = React.useState(null), member = _d[0], setMember = _d[1];
    var _e = React.useState(null), error = _e[0], setError = _e[1];
    var manualScan = function () {
        var code = window.prompt("Scan barcode or enter text");
        if (activeStep === 0 && !loading) {
            handleBookScan(code);
        }
        if (activeStep === 1 && !loading) {
            handleMemberScan(code);
        }
    };
    useHotkeys([["ctrl+mod+B", manualScan]]);
    var handleBookScan = function (code) { return __awaiter(_this, void 0, void 0, function () {
        var bookResponse, bookObject;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    setError(null);
                    return [4 /*yield*/, Api.GetBookByIsbn(code, undefined)];
                case 1:
                    bookResponse = _a.sent();
                    if (!bookResponse.ok) {
                        console.error(bookResponse);
                        setError("Book not found");
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, bookResponse.json()];
                case 2:
                    bookObject = _a.sent();
                    setBook(bookObject);
                    setLoading(false);
                    setActiveStep(1);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleMemberScan = function (code) { return __awaiter(_this, void 0, void 0, function () {
        var memberResponse, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    setError(null);
                    return [4 /*yield*/, Api.GetMemberById(code, undefined)];
                case 1:
                    memberResponse = _b.sent();
                    if (!memberResponse.ok) {
                        console.error(memberResponse);
                        setError("Member not found");
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    _a = setMember;
                    return [4 /*yield*/, memberResponse.json()];
                case 2:
                    _a.apply(void 0, [_b.sent()]);
                    setLoading(false);
                    setActiveStep(2);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleConfirm = function () { return __awaiter(_this, void 0, void 0, function () {
        var apiResult, body, jsonBody;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    setError(null);
                    if (!(props.action === "checkout")) return [3 /*break*/, 2];
                    return [4 /*yield*/, Api.CheckoutBook(book.isbn.value, member.id.value, undefined)];
                case 1:
                    apiResult = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, Api.ReturnBook(book.isbn.value, member.id.value, undefined)];
                case 3:
                    apiResult = _b.sent();
                    _b.label = 4;
                case 4:
                    setLoading(false);
                    if (!apiResult.ok) return [3 /*break*/, 5];
                    setActiveStep(3); // Completed
                    setTimeout(function () {
                        setActiveStep(0);
                        setBook(null);
                        setMember(null);
                        setError(null);
                    }, 2000);
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, apiResult.text()];
                case 6:
                    body = _b.sent();
                    jsonBody = {};
                    try {
                        jsonBody = JSON.parse(body);
                    }
                    catch (err) {
                        jsonBody = {};
                    }
                    console.error("API Error", body);
                    setError((_a = jsonBody.detail) !== null && _a !== void 0 ? _a : "An error occurred while handling your request.");
                    _b.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    useScanDetection({
        onComplete: function (code) {
            if (activeStep === 0 && !loading) {
                handleBookScan(code);
            }
            if (activeStep === 1 && !loading) {
                handleMemberScan(code);
            }
        },
        stopPropagation: true,
        preventDefault: true,
    });
    var activeColor = "#1c7ed6";
    var content = (React.createElement(React.Fragment, null,
        React.createElement(SimpleGrid, { cols: 3, pt: "xl", pb: "xl", mih: "20rem" },
            React.createElement(Center, null,
                !book && (React.createElement(IconBook, { size: "12rem", stroke: "0.75", color: activeStep === 0 ? activeColor : "#ccc", style: { padding: 0, margin: 0 } })),
                book && (React.createElement(Container, { w: "25rem" },
                    React.createElement(BookCard, { id: book.isbn.value, title: book.title, author: book.author, year: book.publicationDate, showActionButton: false, imageUrl: book.coverMedium })))),
            React.createElement(Center, null,
                React.createElement(IconArrowsRight, { size: "3rem", color: "#ccc" })),
            React.createElement(Center, null,
                member && (React.createElement(Box, null,
                    React.createElement(Center, null,
                        React.createElement(Avatar, { size: "12rem" },
                            React.createElement(Minidenticon, { username: member.id.value.toString() +
                                    member.name.firstName +
                                    member.name.lastName }))),
                    React.createElement(Center, null,
                        React.createElement(Text, { size: "xl" },
                            member.name.firstName,
                            " ",
                            member.name.lastName)))),
                !member && (React.createElement(IconUser, { size: "12rem", stroke: "0.75", color: activeStep === 1 ? activeColor : "#ccc", style: { padding: 0, margin: 0 } })))),
        loading && (React.createElement(Text, { color: "blue", size: "xl", align: "center" }, "Searching...")),
        error && (React.createElement(Text, { color: "red", size: "xl", align: "center" }, error)),
        React.createElement(Group, { position: "apart" },
            activeStep > 0 && (React.createElement(Button, { onClick: function () {
                    setActiveStep(0);
                    setError(null);
                    setBook(null);
                    setMember(null);
                }, size: "md", variant: "outline" },
                "Cancel ",
                props.action === "checkout" ? "Check Out" : "Return")),
            activeStep === 2 && (React.createElement(Button, { size: "md", onClick: handleConfirm },
                "Confirm ",
                props.action === "checkout" ? "Check Out" : "Return")))));
    return (React.createElement(Paper, { shadow: "sm", p: "xl", w: "100%", radius: "md" },
        React.createElement(Space, { h: "xl" }),
        React.createElement(Stepper, { active: activeStep, allowNextStepsSelect: false, breakpoint: "sm", size: "xl", iconSize: 60, m: "xl" },
            React.createElement(Stepper.Step, { label: "Step 1", description: "Scan the Book", icon: React.createElement(IconBook, { size: "1.5rem" }), loading: loading && activeStep === 0 }, content),
            React.createElement(Stepper.Step, { label: "Step 2", description: "Scan the Member's Card", icon: React.createElement(IconIdBadge2, { size: "1.5rem" }), loading: loading && activeStep === 1 }, content),
            React.createElement(Stepper.Step, { label: "Step 3", description: props.action === "checkout" ? "Confirm Check Out" : "Confirm Return", icon: React.createElement(IconCheck, { size: "1.5rem" }), loading: loading && activeStep === 2 }, content),
            React.createElement(Stepper.Completed, null,
                React.createElement(Center, { mih: "20rem" },
                    React.createElement(IconCircleCheck, { size: "5rem", color: "#80D672", stroke: 0.75 }),
                    React.createElement(Text, { style: { display: "block" }, size: "xl", color: "#80D672" },
                        "Book ",
                        props.action === "checkout" ? "Checked Out" : "Returned"))))));
}
export default {
    Loader: loader,
    Action: action,
    Page: LendingPage,
};
