import { Accordion, Container, Modal, Text } from "@mantine/core";
import * as React from "react";
import { useRouteError } from "react-router-dom";
import StockBackground from "../sharedComponents/stockBackground";
export function UnhandledErrorPage() {
    var error = useRouteError();
    var errorDetails = JSON.stringify(error);
    return (React.createElement(React.Fragment, null,
        React.createElement(StockBackground, null),
        React.createElement(Modal, { opened: true, onClose: function () {
                window.location.reload();
            }, title: "Something went wrong." },
            React.createElement(Container, { size: 960 },
                React.createElement(Text, null, "TODO: Make this nicer"),
                React.createElement(Accordion, null,
                    React.createElement(Accordion.Item, { value: "errordetails" },
                        React.createElement(Accordion.Control, null, "Error Details"),
                        React.createElement(Accordion.Panel, null,
                            React.createElement("pre", null, errorDetails))))))));
}
export default {
    Page: UnhandledErrorPage,
};
