var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export function IsAuthenticated() {
    return localStorage.getItem("token") !== null;
}
export function RegisterUser(registrationData, signal) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/users", {
                        method: "POST",
                        body: JSON.stringify(registrationData),
                        signal: signal,
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function VerifyEmail(userId, verificationCode, signal) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/users/".concat(userId, "/emailVerification"), {
                        method: "PUT",
                        signal: signal,
                        body: JSON.stringify({ verificationCode: verificationCode }),
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function ResendVerification(userId, signal) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/users/".concat(userId, "/resendVerification"), {
                        method: "POST",
                        signal: signal,
                        headers: { Accept: "application/json", },
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function Login(email, password, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var response, tokenResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/api/users/login", {
                        method: "POST",
                        signal: signal,
                        body: JSON.stringify({ email: email, password: password }),
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json"
                        },
                    })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    tokenResponse = _a.sent();
                    localStorage.setItem("token", tokenResponse.token);
                    _a.label = 3;
                case 3: return [2 /*return*/, response];
            }
        });
    });
}
export function Logout() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            localStorage.removeItem("token");
            return [2 /*return*/];
        });
    });
}
export function GetCurrentUser(signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/users/me", {
                            method: "GET",
                            signal: signal,
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
export function GetMembers(skip, take, nameFilter, filteredTags, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token, params, splitTags, _i, splitTags_1, tag, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    console.log("GetMembers", skip, take, nameFilter, filteredTags);
                    params = new URLSearchParams();
                    params.append("skip", skip.toString());
                    params.append("take", take.toString());
                    if (nameFilter) {
                        params.append("name", nameFilter);
                    }
                    if (filteredTags) {
                        splitTags = filteredTags.split(",");
                        for (_i = 0, splitTags_1 = splitTags; _i < splitTags_1.length; _i++) {
                            tag = splitTags_1[_i];
                            params.append("filteredTag", tag);
                        }
                    }
                    return [4 /*yield*/, fetch("/api/members?" + params, {
                            method: "GET",
                            signal: signal,
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
export function GetMemberTags(signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/members/tags", {
                            method: "GET",
                            signal: signal,
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
export function BulkImportMembers(memberData, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/members/bulk", {
                            method: "POST",
                            body: JSON.stringify({
                                members: memberData
                            }),
                            signal: signal,
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function UpdateTag(tagId, label, colourCode, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/members/tags/".concat(tagId), {
                            method: "PUT",
                            signal: signal,
                            body: JSON.stringify({ colourCode: colourCode, label: label }),
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function CreateTag(label, colourCode, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/members/tags", {
                            method: "POST",
                            signal: signal,
                            body: JSON.stringify({ colourCode: colourCode, label: label }),
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function DeleteTag(tagId, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/members/tags/".concat(tagId), {
                            method: "DELETE",
                            signal: signal,
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function GetBookByIsbn(isbn, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/books?isbn=".concat(isbn), {
                            method: "GET",
                            signal: signal,
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function GetMemberById(memberId, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/members/".concat(memberId), {
                            method: "GET",
                            signal: signal,
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function CheckoutBook(isbn, memberId, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/library/checkout", {
                            method: "POST",
                            signal: signal,
                            body: JSON.stringify({ isbn: isbn, memberId: memberId }),
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function ReturnBook(isbn, memberId, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    return [4 /*yield*/, fetch("/api/library/return", {
                            method: "POST",
                            signal: signal,
                            body: JSON.stringify({ isbn: isbn, memberId: memberId }),
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function GetLendingHistory(skip, take, loansToInclude, nameFilter, bookFilter, filteredTags, signal) {
    return __awaiter(this, void 0, void 0, function () {
        var token, params, splitTags, _i, splitTags_2, tag, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    params = new URLSearchParams();
                    params.append("skip", skip.toString());
                    params.append("take", take.toString());
                    if (loansToInclude) {
                        params.append("loansToInclude", loansToInclude.toString());
                    }
                    if (nameFilter) {
                        params.append("nameFilter", nameFilter);
                    }
                    if (bookFilter) {
                        params.append("bookFilter", bookFilter);
                    }
                    if (filteredTags) {
                        splitTags = filteredTags.split(",");
                        for (_i = 0, splitTags_2 = splitTags; _i < splitTags_2.length; _i++) {
                            tag = splitTags_2[_i];
                            params.append("filteredTag", tag);
                        }
                    }
                    return [4 /*yield*/, fetch("/api/library/history?" + params, {
                            method: "GET",
                            signal: signal,
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer ".concat(token)
                            },
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
export default {
    RegisterUser: RegisterUser,
    VerifyEmail: VerifyEmail,
    ResendVerification: ResendVerification,
    Login: Login,
    Logout: Logout,
    GetMembers: GetMembers,
    GetMemberById: GetMemberById,
    BulkImportMembers: BulkImportMembers,
    GetMemberTags: GetMemberTags,
    CreateTag: CreateTag,
    UpdateTag: UpdateTag,
    DeleteTag: DeleteTag,
    GetBookByIsbn: GetBookByIsbn,
    CheckoutBook: CheckoutBook,
    ReturnBook: ReturnBook,
    GetLendingHistory: GetLendingHistory,
    GetCurrentUser: GetCurrentUser,
    IsAuthenticated: IsAuthenticated,
};
