import { Box, createStyles } from "@mantine/core";
import * as React from "react";
import pageBackground from "../assets/login-background.jpg";
var useStyles = createStyles(function () { return ({
    backgroundImage: {
        position: "fixed",
        width: "100%",
        height: "100%",
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        zIndex: -1,
        background: "linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(".concat(pageBackground, ")"),
        backgroundSize: "cover",
        backgroundPosition: "center top",
    },
}); });
export default function StockBackground() {
    var classes = useStyles().classes;
    return React.createElement(Box, { className: classes.backgroundImage });
}
