import { Center, Pagination, Space, Stack, Table, Text, Group, Avatar, } from "@mantine/core";
import * as React from "react";
import Minidenticon from "../../sharedComponents/identicon";
import noCover from "../../assets/no-cover.png";
import Tag from "../../sharedComponents/tag";
export function HistoryTable(_a) {
    var items = _a.items, tags = _a.tags, showEndDate = _a.showEndDate, page = _a.page, totalPages = _a.totalPages, onPageChange = _a.onPageChange;
    if (items.length === 0 && page === 1) {
        return (React.createElement(Center, { m: "xl" },
            React.createElement(Text, { pt: "xl" }, "No loans match your filters. Try changing your search criteria.")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { sx: { width: "100%" }, verticalSpacing: "xs", highlightOnHover: true },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Member"),
                    React.createElement("th", null, "Book"),
                    React.createElement("th", null, "Borrowed \u2193"),
                    React.createElement("th", null, "Returned"))),
            React.createElement("tbody", null, items.map(function (i) { return (React.createElement(HistoryTableRow, { book: i.book, member: i.member, loan: i.loan, tags: tags, showEndDate: showEndDate })); }))),
        React.createElement(Space, { h: "xl" }),
        React.createElement(Center, null,
            React.createElement(Pagination, { total: totalPages, value: page, onChange: onPageChange }))));
}
function HistoryTableRow(_a) {
    var _b, _c;
    var book = _a.book, member = _a.member, loan = _a.loan, tags = _a.tags, showEndDate = _a.showEndDate;
    return (React.createElement("tr", null,
        React.createElement("td", { style: { maxWidth: "10rem" } },
            React.createElement(Group, { spacing: "md", align: "center" },
                React.createElement(Avatar, { size: "lg" },
                    React.createElement(Minidenticon, { username: member.id.value.toString() +
                            member.name.firstName +
                            member.name.lastName })),
                React.createElement(Stack, { spacing: "xs" },
                    React.createElement(Text, { fz: "md", fw: 500 },
                        member.name.firstName,
                        " ",
                        member.name.lastName),
                    React.createElement("div", null, member.tags
                        .map(function (tag) { return tag.tagId.value; })
                        .map(function (tag) { return tags.find(function (t) { return t.id.value === tag; }); })
                        .filter(function (tag) { return !!tag; })
                        .sort(function (a, b) { return a.label.localeCompare(b.label); })
                        .map(function (tag) { return (React.createElement(Tag, { size: "md", label: tag.label, color: tag.colour.value })); }))))),
        React.createElement("td", null,
            React.createElement(Group, { spacing: "md", align: "center" },
                React.createElement("img", { src: (_b = book.coverSmall) !== null && _b !== void 0 ? _b : noCover, style: { objectFit: "scale-down", height: "56px" } }),
                React.createElement(Stack, { spacing: "xs" },
                    React.createElement(Text, { fz: "md", fw: 500 }, book.title),
                    React.createElement(Text, { c: "dimmed", fz: "sm" },
                        book.author,
                        ", ",
                        book.publicationDate)))),
        React.createElement("td", null, loan.startDate),
        React.createElement("td", null, (_c = loan.endDate) !== null && _c !== void 0 ? _c : "Not Returned")));
}
