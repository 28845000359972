var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import Api from "../../api/api";
import { useLoaderData } from "react-router-typesafe";
import { Avatar, Center, Paper, SimpleGrid, Text, Space } from "@mantine/core";
import Minidenticon from "../../sharedComponents/identicon";
import Barcode from "react-barcode";
var loader = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        var url, filteredTags, nameFilter, membersResponse;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = new URL(request.url);
                    filteredTags = url.searchParams.get("tags");
                    nameFilter = url.searchParams.get("nameFilter");
                    return [4 /*yield*/, Api.GetMembers(0, 1000, nameFilter, filteredTags, request.signal)];
                case 1:
                    membersResponse = _b.sent();
                    if (!membersResponse.ok) {
                        throw membersResponse;
                    }
                    return [4 /*yield*/, membersResponse.json()];
                case 2: return [2 /*return*/, (_b.sent()).items];
            }
        });
    });
});
function MemberCardPage() {
    var members = useLoaderData();
    console.log(members);
    return (React.createElement(SimpleGrid, { cols: 3 }, members.map(function (m) { return (React.createElement(MemberCard, { code: m.id.value.toString(), firstName: m.name.firstName, lastName: m.name.lastName })); })));
}
export function MemberCard(_a) {
    var code = _a.code, firstName = _a.firstName, lastName = _a.lastName;
    return (React.createElement(Paper, { radius: "md", withBorder: true, p: "lg", sx: {
            pageBreakInside: "avoid",
        } },
        React.createElement(Text, { ta: "center", fz: "sm", weight: 500, mb: "xl" }, "CleverLibrary Member Card"),
        React.createElement(Avatar, { size: 120, radius: 120, mx: "auto" },
            React.createElement(Minidenticon, { username: code.toString() + firstName + lastName })),
        React.createElement(Text, { ta: "center", fz: "xl", weight: 500, mt: "md" },
            firstName,
            " ",
            lastName),
        React.createElement(Space, { h: "xl" }),
        React.createElement(Center, null,
            React.createElement(Barcode, { value: code.padStart(6, "0"), width: 4, height: 60 }))));
}
export default {
    Loader: loader,
    Page: MemberCardPage,
};
