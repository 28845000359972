var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { createStyles, Title, Text, rem, Table, Badge, } from "@mantine/core";
var loader = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, { success: true }];
        });
    });
});
var action = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, { success: true }];
        });
    });
});
var sampleStats = [
    {
        stats: "123",
        title: "Members",
        description: "18 members have books on loan. 24% of members have borrowed a book in the last 30 days.",
    },
    {
        stats: "24",
        title: "Books on Loan",
        description: "3 books returned in the last week.",
    },
    {
        stats: "587",
        title: "Books in Catalogue",
        description: "123 added, 3 removed in the last 30 days.",
    },
    {
        stats: "123",
        title: "Some other stat",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
];
function DashboardPage() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { order: 2 }, "Dashboard Coming Soon...")));
    /*<Title order={3}>
          <span> Stats at a glance..</span>
        </Title>
        <Space h="xl" />
        <StatsGroup data={sampleStats} />
        <Space h="xl" />
        <Title order={3}>
          <span> Books on Loan</span>
        </Title>
        <Space h="xl" />
        <Paper shadow="sm" p="xl" pt="xs" radius="md">
          <LoansTable />
        </Paper>
      </>
    ); */
}
var useStyles = createStyles(function (theme) {
    var _a, _b;
    return ({
        root: (_a = {
                display: "flex",
                backgroundImage: "linear-gradient(-60deg, ".concat(theme.colors[theme.primaryColor][4], " 0%, ").concat(theme.colors[theme.primaryColor][7], " 100%)"),
                padding: "calc(".concat(theme.spacing.xl, " * 1.5)"),
                borderRadius: theme.radius.md
            },
            _a[theme.fn.smallerThan("sm")] = {
                flexDirection: "column",
            },
            _a),
        title: {
            color: theme.white,
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: theme.fontSizes.sm,
        },
        count: {
            color: theme.white,
            fontSize: rem(32),
            lineHeight: 1,
            fontWeight: 700,
            marginBottom: theme.spacing.md,
            fontFamily: "Greycliff CF, ".concat(theme.fontFamily),
        },
        description: {
            color: theme.colors[theme.primaryColor][0],
            fontSize: theme.fontSizes.sm,
            marginTop: rem(5),
        },
        stat: {
            flex: 1,
            "& + &": (_b = {
                    paddingLeft: theme.spacing.xl,
                    marginLeft: theme.spacing.xl,
                    borderLeft: "".concat(rem(1), " solid ").concat(theme.colors[theme.primaryColor][3])
                },
                _b[theme.fn.smallerThan("sm")] = {
                    paddingLeft: 0,
                    marginLeft: 0,
                    borderLeft: 0,
                    paddingTop: theme.spacing.xl,
                    marginTop: theme.spacing.xl,
                    borderTop: "".concat(rem(1), " solid ").concat(theme.colors[theme.primaryColor][3]),
                },
                _b),
        },
    });
});
function StatsGroup(_a) {
    var data = _a.data;
    var classes = useStyles().classes;
    var stats = data.map(function (stat) { return (React.createElement("div", { key: stat.title, className: classes.stat },
        React.createElement(Text, { className: classes.count }, stat.stats),
        React.createElement(Text, { className: classes.title }, stat.title),
        React.createElement(Text, { className: classes.description }, stat.description))); });
    return React.createElement("div", { className: classes.root }, stats);
}
var tagColors = {
    "Year 3": "blue",
    "Year 4": "cyan",
    "Year 5": "pink",
    "Year 6": "red",
    "Oak Class": "yellow",
    "Lime Class": "teal",
};
function LoansTable() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { sx: { minWidth: 800 }, verticalSpacing: "md" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Title"),
                    React.createElement("th", null, "Author"),
                    React.createElement("th", null, "Member"),
                    React.createElement("th", null, "Borrowed"))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, "Dune"),
                    React.createElement("td", null, "Frank Herbert"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "blue", variant: "light", ml: "xs" }, "Year 5"),
                        React.createElement(Badge, { color: "green", variant: "light", ml: "xs" }, "Lime Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Dune"),
                    React.createElement("td", null, "Frank Herbert"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Matilda Wormwood"),
                        React.createElement(Badge, { color: "blue", variant: "light", ml: "xs" }, "Year 5"),
                        React.createElement(Badge, { color: "green", variant: "light", ml: "xs" }, "Lime Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Dune"),
                    React.createElement("td", null, "Frank Herbert"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Matilda Wormwood"),
                        " ",
                        React.createElement(Badge, { color: "blue", variant: "light", ml: "xs" }, "Year 5"),
                        React.createElement(Badge, { color: "green", variant: "light", ml: "xs" }, "Lime Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Dune"),
                    React.createElement("td", null, "Frank Herbert"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Matilda Wormwood"),
                        " ",
                        React.createElement(Badge, { color: "blue", variant: "light", ml: "xs" }, "Year 5"),
                        React.createElement(Badge, { color: "green", variant: "light", ml: "xs" }, "Lime Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Dune"),
                    React.createElement("td", null, "Frank Herbert"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Matilda Wormwood"),
                        " ",
                        React.createElement(Badge, { color: "blue", variant: "light", ml: "xs" }, "Year 5"),
                        React.createElement(Badge, { color: "green", variant: "light", ml: "xs" }, "Lime Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Rich Knight"),
                        React.createElement(Badge, { color: "yellow", variant: "light", ml: "xs" }, "Year 3"),
                        React.createElement(Badge, { color: "pink", variant: "light", ml: "xs" }, "Hawthorn Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)")),
                React.createElement("tr", null,
                    React.createElement("td", null, "Foundation and Empire"),
                    React.createElement("td", null, "Isaac Asimov"),
                    React.createElement("td", null,
                        React.createElement("span", null, "Becky Harding"),
                        React.createElement(Badge, { color: "indigo", variant: "light", ml: "xs" }, "Year 4"),
                        React.createElement(Badge, { color: "orange", variant: "light", ml: "xs" }, "Ash Class")),
                    React.createElement("td", null, "12/06/2023 (23 days ago)"))))));
}
export default {
    Loader: loader,
    Action: action,
    Page: DashboardPage,
};
