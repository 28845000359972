import * as React from "react";
import { Avatar, Button, Center, Group, Pagination, Space, Table, Text, } from "@mantine/core";
import Tag from "../../sharedComponents/tag";
import ComingSoonTooltip from "../../sharedComponents/comingSoonTooltip";
import Minidenticon from "../../sharedComponents/identicon";
export default function MembersTable(_a) {
    var members = _a.members, tags = _a.tags, page = _a.page, totalPages = _a.totalPages, onPageChange = _a.onPageChange;
    if (members.length === 0 && page === 1) {
        return (React.createElement(Center, { m: "xl" },
            React.createElement(Text, null, "No members found matching your filters. Try changing your search criteria.")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { verticalSpacing: "xs", highlightOnHover: true },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Name \u2193"),
                    React.createElement("th", null, "Member #"),
                    React.createElement("th", null, "Tags"),
                    React.createElement("th", null))),
            React.createElement("tbody", null, members.map(function (member) { return (React.createElement("tr", { key: member.id.value },
                React.createElement("td", null,
                    React.createElement(Group, null,
                        React.createElement(Avatar, { display: "inline-block" },
                            React.createElement(Minidenticon, { username: member.id.value.toString() +
                                    member.name.firstName +
                                    member.name.lastName, saturation: undefined, lightness: undefined })),
                        React.createElement(Text, null,
                            member.name.firstName,
                            " ",
                            member.name.lastName))),
                React.createElement("td", null, member.id.value.toString().padStart(6, "0")),
                React.createElement("td", null, member.tags
                    .map(function (tag) { return tag.tagId.value; })
                    .map(function (tag) { return tags.find(function (t) { return t.id.value === tag; }); })
                    .filter(function (tag) { return !!tag; })
                    .sort(function (a, b) { return a.label.localeCompare(b.label); })
                    .map(function (tag) { return (React.createElement(Tag, { size: "lg", label: tag.label, color: tag.colour.value })); })),
                React.createElement("td", null,
                    React.createElement(Group, { spacing: "md", position: "right" },
                        React.createElement(ComingSoonTooltip, null,
                            React.createElement(Button, { variant: "outline" }, "Edit")),
                        React.createElement(ComingSoonTooltip, null,
                            React.createElement(Button, { variant: "outline", color: "red" }, "Delete")))))); }))),
        React.createElement(Space, { h: "xl" }),
        React.createElement(Center, null,
            React.createElement(Pagination, { total: totalPages, value: page, onChange: onPageChange }))));
}
