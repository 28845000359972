var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Anchor, Container, Paper, PasswordInput, Stack, Text, TextInput, Title, } from "@mantine/core";
import { useForm } from "@mantine/form";
import * as React from "react";
import { Link, redirect, useSubmit } from "react-router-dom";
import Api from "../../api/api";
import NotificationHelpers from "../../utils/notificationHelpers";
import StockBackground from "../../sharedComponents/stockBackground";
import SubmitButton from "../../sharedComponents/submitButton";
var actionHandler = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        var formData, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, request.json()];
                case 1:
                    formData = _b.sent();
                    return [4 /*yield*/, Api.RegisterUser(formData, request.signal)];
                case 2:
                    response = _b.sent();
                    if (!response.ok) {
                        NotificationHelpers.ShowApiError(response);
                        // We've displayed an error, so just swallow and remain on the current form
                        return [2 /*return*/, { ok: true }];
                    }
                    return [2 /*return*/, redirect("/register/success?email=" + formData.email)];
            }
        });
    });
});
function RegistrationPage() {
    var reactRouterSubmit = useSubmit();
    var form = useForm({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            unhashedPassword: "",
            confirmPassword: "",
        },
        validate: {
            firstName: function (value) {
                return value.trim().length < 2
                    ? "First name must be at least 2 characters long"
                    : null;
            },
            lastName: function (value) {
                return value.trim().length < 2
                    ? "Surname must be at least 2 characters long"
                    : null;
            },
            email: function (value) { return (!value.includes("@") ? "Invalid email" : null); },
            unhashedPassword: function (value) {
                return value.length < 10 ? "Password must be at least 10 characters" : null;
            },
            confirmPassword: function (value, values) {
                return value != values.unhashedPassword ? "Passwords do not match" : null;
            },
        },
        validateInputOnBlur: true,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(StockBackground, null),
        React.createElement(Container, { size: 520, my: 40 },
            React.createElement(Paper, { withBorder: true, shadow: "md", p: 30, mt: 30, radius: "md" },
                React.createElement("form", { onSubmit: form.onSubmit(function (values) {
                        return reactRouterSubmit(values, {
                            method: "post",
                            encType: "application/json",
                        });
                    }) },
                    React.createElement(Stack, null,
                        React.createElement(Title, { align: "center" }, "Create a Clever Library Account"),
                        React.createElement(TextInput, __assign({ name: "firstName", label: "First Name", placeholder: "First Name", required: true }, form.getInputProps("firstName"))),
                        React.createElement(TextInput, __assign({ name: "lastName", label: "Surname", placeholder: "Surname", required: true }, form.getInputProps("lastName"))),
                        React.createElement(TextInput, __assign({ name: "email", label: "Email", placeholder: "you@gmail.com", required: true }, form.getInputProps("email"))),
                        React.createElement(PasswordInput, __assign({ name: "unhashedPassword", label: "Password", placeholder: "Your password", required: true }, form.getInputProps("unhashedPassword"))),
                        React.createElement(PasswordInput, __assign({ nmame: "confirmPassword", label: "Confirm password", placeholder: "Confirm password", required: true }, form.getInputProps("confirmPassword")))),
                    React.createElement(SubmitButton, { enabled: !form.isTouched() || form.isValid(), text: "Create", fullWidth: true, mt: "xl" }),
                    React.createElement(Text, { color: "dimmed", size: "sm", align: "center", mt: 5 },
                        "Already have an account?",
                        " ",
                        React.createElement(Anchor, { component: Link, to: "/login" }, "Sign in")))))));
}
export default {
    Action: actionHandler,
    Page: RegistrationPage,
};
