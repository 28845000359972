var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { Button, FileButton, Group, MultiSelect, Paper, Space, TextInput, Title, } from "@mantine/core";
import * as csv from "csvtojson";
import { IconUser, IconTag } from "@tabler/icons-react";
import { useLoaderData, } from "react-router-typesafe";
import Api from "../../api/api";
import { Form, Link, Outlet, useSearchParams, useSubmit, } from "react-router-dom";
import MembersPageHelp from "./membersPageHelp";
import MembersTable from "./membersTable";
import RouterLoadingOverlay from "../../sharedComponents/loadingOverlay";
import NotificationHelpers from "../../utils/notificationHelpers";
import { useForm } from "@mantine/form";
import ComingSoonTooltip from "../../sharedComponents/comingSoonTooltip";
import TagMultiSelectItem from "../../sharedComponents/tagMultiSelectItem";
var PAGE_SIZE = 10;
var loader = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        var url, pageString, filteredTags, nameFilter, pageNumber, skip, take, _b, membersResponse, tagsResponse, sortedTags;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    url = new URL(request.url);
                    pageString = url.searchParams.get("page");
                    filteredTags = url.searchParams.get("tags");
                    nameFilter = url.searchParams.get("nameFilter");
                    pageNumber = Number(pageString);
                    if (isNaN(pageNumber) || pageNumber < 1) {
                        pageNumber = 1;
                    }
                    skip = (pageNumber - 1) * PAGE_SIZE;
                    take = PAGE_SIZE;
                    return [4 /*yield*/, Promise.all([
                            Api.GetMembers(skip, take, nameFilter, filteredTags, request.signal),
                            Api.GetMemberTags(request.signal),
                        ])];
                case 1:
                    _b = _d.sent(), membersResponse = _b[0], tagsResponse = _b[1];
                    if (!membersResponse.ok) {
                        throw membersResponse;
                    }
                    if (!tagsResponse.ok) {
                        throw tagsResponse;
                    }
                    return [4 /*yield*/, tagsResponse.json()];
                case 2:
                    sortedTags = (_d.sent()).sort(function (a, b) {
                        return a.label.localeCompare(b.label);
                    });
                    _c = {};
                    return [4 /*yield*/, membersResponse.json()];
                case 3: return [2 /*return*/, (_c.members = _d.sent(),
                        _c.tags = sortedTags,
                        _c.page = pageNumber,
                        _c)];
            }
        });
    });
});
var action = (function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestData, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, request.json()];
                case 1:
                    requestData = _b.sent();
                    if (!(requestData.intent === "bulkImport")) return [3 /*break*/, 6];
                    return [4 /*yield*/, Api.BulkImportMembers(requestData.jsonRows, request.signal)];
                case 2:
                    response = _b.sent();
                    if (!!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, NotificationHelpers.ShowApiError(response)];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    NotificationHelpers.ShowSuccess("".concat(requestData.jsonRows.length, " Members imported"));
                    _b.label = 5;
                case 5: return [2 /*return*/, { ok: true }];
                case 6: return [2 /*return*/, { success: true }];
            }
        });
    });
});
function MembersPage() {
    var _this = this;
    var _a, _b, _c;
    var _d = useLoaderData(), members = _d.members, tags = _d.tags, page = _d.page;
    var _e = useSearchParams(), searchParams = _e[0], setSearchParams = _e[1];
    var reactRouterSubmit = useSubmit();
    var handlePageChange = function (page) {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };
    var handleFileSelection = function (payload) { return __awaiter(_this, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _b = (_a = csv())
                        .fromString;
                    return [4 /*yield*/, payload.text()];
                case 1:
                    _b.apply(_a, [_c.sent()])
                        .then(function (jsonMembers) {
                        reactRouterSubmit({
                            intent: "bulkImport",
                            jsonRows: jsonMembers,
                        }, {
                            method: "post",
                            encType: "application/json",
                        });
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var tagSelectorData = tags.sort().map(function (t) {
        return {
            value: t.id.value,
            label: t.label,
            tag: t,
        };
    });
    var filterForm = useForm({
        initialValues: {
            nameFilter: (_a = searchParams.get("nameFilter")) !== null && _a !== void 0 ? _a : "",
            tags: (_c = (_b = searchParams.get("tags")) === null || _b === void 0 ? void 0 : _b.split(",")) !== null && _c !== void 0 ? _c : [],
        },
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Group, { position: "apart" },
            React.createElement(Group, { position: "left" },
                React.createElement(Title, { order: 3 }, "Members")),
            React.createElement(Group, { position: "right" },
                React.createElement(MembersPageHelp, null))),
        React.createElement(Space, { h: "sm" }),
        React.createElement(Group, { position: "apart", mb: "sm", mt: "xl", align: "bottom" },
            React.createElement(Group, { component: Form, position: "left", align: "top" },
                React.createElement(TextInput, __assign({ name: "nameFilter", placeholder: "Filter by name...", size: "sm", sx: { width: "15rem", display: "inline-block" }, icon: React.createElement(IconUser, { size: "0.8rem", stroke: 1.5 }), rightSectionWidth: 70, styles: { rightSection: { pointerEvents: "none" } } }, filterForm.getInputProps("nameFilter"))),
                React.createElement(MultiSelect, __assign({ name: "tags", sx: { width: "20rem", display: "inline-block" }, icon: React.createElement(IconTag, { size: "0.8rem", stroke: 1.5 }), data: tagSelectorData, placeholder: "Filter by tags...", itemComponent: function (item) { return React.createElement(TagMultiSelectItem, __assign({}, item)); } }, filterForm.getInputProps("tags"), { searchable: true })),
                React.createElement(Button, { type: "submit", variant: "outline", color: "blue" }, "Filter")),
            React.createElement(Group, { position: "right", align: "top" },
                React.createElement(Button, { component: Link, to: "print?kiosk=true&" + searchParams, target: "_blank", rel: "noopener noreferrer" }, "Print Cards"),
                React.createElement(Button, { component: Link, to: "tags" }, "Manage Tags"),
                React.createElement(ComingSoonTooltip, null,
                    React.createElement(Button, null, "Add Member")),
                React.createElement(FileButton, { onChange: handleFileSelection, accept: "text/csv" }, function (props) { return (React.createElement(React.Fragment, null,
                    React.createElement(Button, __assign({}, props),
                        React.createElement(RouterLoadingOverlay, { immediate: true, match: function (navData) {
                                return (navData.json !== undefined &&
                                    navData.json.intent === "bulkImport");
                            } }),
                        "Import CSV"))); }))),
        React.createElement(Space, { h: "xs" }),
        React.createElement(Paper, { shadow: "sm", p: "xl", pt: "xs", radius: "md", pos: "relative" },
            React.createElement(RouterLoadingOverlay, { immediate: false, match: function () { return true; } }),
            React.createElement(MembersTable, { members: members.items, tags: tags, page: page, totalPages: Math.ceil(members.totalResults / PAGE_SIZE), onPageChange: handlePageChange })),
        React.createElement(Outlet, { context: { tags: tags } })));
}
export default {
    Loader: loader,
    Action: action,
    Page: MembersPage,
};
