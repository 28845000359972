var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Button, Anchor, UnstyledButton, LoadingOverlay } from "@mantine/core";
import { useFetcher, useNavigation, useSubmit } from "react-router-dom";
export function ActionButton(props) {
    return React.createElement(ActionBase, __assign({ renderAsLink: false, navigate: false }, props));
}
export function NavActionButton(props) {
    return React.createElement(ActionBase, __assign({ renderAsLink: false, navigate: true }, props));
}
export function ActionLink(props) {
    return React.createElement(ActionBase, __assign({ renderAsLink: true, navigate: false }, props));
}
export function NavActionLink(props) {
    return React.createElement(ActionBase, __assign({ renderAsLink: true, navigate: true }, props));
}
export function ActionBase(_a) {
    var text = _a.text, route = _a.route, method = _a.method, body = _a.body, navigate = _a.navigate, renderAsLink = _a.renderAsLink, enabled = _a.enabled, restProps = __rest(_a, ["text", "route", "method", "body", "navigate", "renderAsLink", "enabled"]);
    var fetcher = useFetcher();
    var submit = useSubmit();
    var navigation = useNavigation();
    var navSubmit = React.useCallback(function () {
        return submit(__assign({}, body), { method: method, action: route, encType: "application/json" });
    }, [body, method, route]);
    var fetcherSubmit = React.useCallback(function () {
        return fetcher.submit(__assign({}, body), { method: method, action: route, encType: "application/json" });
    }, [body, method, route]);
    var state = navigate ? navigation.state : fetcher.state;
    var isSubmitting = state === "submitting" || state === "loading";
    var submitFunction = navigate ? navSubmit : fetcherSubmit;
    var loadingOverlay = (React.createElement(LoadingOverlay, { loaderProps: { size: "sm", variant: "oval" }, visible: isSubmitting }));
    if (renderAsLink) {
        return (React.createElement(UnstyledButton, __assign({ component: Anchor, onClick: submitFunction, disabled: !enabled || isSubmitting }, restProps),
            loadingOverlay,
            React.createElement(Anchor, null, text)));
    }
    else {
        return (React.createElement(Button, __assign({ type: "button", onClick: submitFunction, disabled: !enabled || isSubmitting }, restProps),
            loadingOverlay,
            text));
    }
}
