import * as React from "react";
import { useEffect } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { nprogress } from "@mantine/nprogress";
var loadingStarter;
var loadingShowing = false;
export default function NavProgressWatcher() {
    var _a = useNavigation(), state = _a.state, location = _a.location;
    // When nav state changes, notify nprogress
    useEffect(function () {
        clearTimeout(loadingStarter);
        if (state === "idle") {
            if (loadingShowing) {
                nprogress.complete();
                loadingShowing = false;
            }
        }
        else {
            loadingShowing = false;
            nprogress.reset();
            // Don't start showing progress unless it has been 100ms
            loadingStarter = setTimeout(function () {
                loadingShowing = true;
                nprogress.start();
                //Get it past the navbar
                nprogress.set(40);
            }, 500);
        }
    }, [state, location]);
    return React.createElement(Outlet, null);
}
